// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TqenVhY8L: {hover: true}};

const cycleOrder = ["TqenVhY8L"];

const serializationHash = "framer-SsKgT"

const variantClassNames = {TqenVhY8L: "framer-v-y6pqwj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, sectionName, width, ...props}) => { return {...props, Uh2vRG0xI: sectionName ?? props.Uh2vRG0xI ?? "Label"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;sectionName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Uh2vRG0xI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TqenVhY8L", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y6pqwj", className, classNames)} data-framer-name={"Section"} layoutDependency={layoutDependency} layoutId={"TqenVhY8L"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"TqenVhY8L-hover": {backgroundColor: "var(--token-97364bc5-b64c-42c9-afbb-701208e526df, rgb(236, 233, 254))"}}} {...addPropertyOverrides({"TqenVhY8L-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dlbmVyYWwgU2FucyBNZWRpdW0=", "--framer-font-family": "\"General Sans Medium\", \"General Sans Medium Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "0.2px", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-39198b1a-f6c0-4cc9-b1b3-31aaa8a297ca, rgb(23, 23, 33)))"}}>Productos</motion.p></React.Fragment>} className={"framer-193umhw"} fonts={["CUSTOM;General Sans Medium"]} layoutDependency={layoutDependency} layoutId={"AaGyvcxQa"} style={{"--extracted-r6o4lv": "var(--token-39198b1a-f6c0-4cc9-b1b3-31aaa8a297ca, rgb(23, 23, 33))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={Uh2vRG0xI} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-SsKgT[data-border=\"true\"]::after, .framer-SsKgT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SsKgT.framer-kcbn58, .framer-SsKgT .framer-kcbn58 { display: block; }", ".framer-SsKgT.framer-y6pqwj { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 8px 8px 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-SsKgT .framer-193umhw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SsKgT.framer-y6pqwj { gap: 0px; } .framer-SsKgT.framer-y6pqwj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-SsKgT.framer-y6pqwj > :first-child { margin-left: 0px; } .framer-SsKgT.framer-y6pqwj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 62
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"AdhHpkaPf":{"layout":["auto","auto"]}}}
 * @framerVariables {"Uh2vRG0xI":"sectionName"}
 * @framerImmutableVariables true
 */
const Framerw1Wra6KDg: React.ComponentType<Props> = withCSS(Component, css, "framer-SsKgT") as typeof Component;
export default Framerw1Wra6KDg;

Framerw1Wra6KDg.displayName = "Selector";

Framerw1Wra6KDg.defaultProps = {height: 40, width: 62};

addPropertyControls(Framerw1Wra6KDg, {Uh2vRG0xI: {defaultValue: "Label", displayTextArea: false, title: "Section name", type: ControlType.String}} as any)

addFonts(Framerw1Wra6KDg, [{family: "General Sans Medium", url: "https://framerusercontent.com/assets/qK8x3dBkAdwT7mBv3LHsJPyO52w.woff2"}])